var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { id: "SessionList", fluid: "", tag: "section" } },
    [
      _c(
        "v-overlay",
        {
          attrs: { "z-index": "60" },
          model: {
            value: _vm.loaderlay,
            callback: function ($$v) {
              _vm.loaderlay = $$v
            },
            expression: "loaderlay",
          },
        },
        [
          _c(
            "v-progress-circular",
            {
              staticClass: "l2",
              attrs: { indeterminate: "", size: "100", color: "primary" },
            },
            [
              _c("v-progress-circular", {
                staticClass: "l3",
                attrs: { indeterminate: "", size: "75", color: "primary" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { staticClass: "v-card--material true pa-3 py-3" },
                [
                  _c("card-heading", {
                    attrs: { color: _vm.sectionColor, title: "Sessions" },
                    scopedSlots: _vm._u([
                      {
                        key: "append",
                        fn: function () {
                          return [
                            _vm._usrFlagsSome({
                              key: `session.list`,
                              val: _vm.permissions.READ,
                            })
                              ? _c("ConfirmDialog2", {
                                  attrs: {
                                    persistent: "",
                                    confirmBtnText: `Export anyway`,
                                    confirmBtnColor: "error",
                                    cancelBtnColor: "kajot-text",
                                    cancelBtnText: "Back",
                                    shouldShow:
                                      (_vm.filterIsEmpty && !_vm.dontShow) ||
                                      _vm.total_records > 4000,
                                  },
                                  on: { confirm: _vm.onExportOpen },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "body",
                                        fn: function () {
                                          return [
                                            _c("b", [
                                              _vm._v(
                                                " Exporting large amounts of data may significantly impact server load and increase wait times. "
                                              ),
                                            ]),
                                            _c("br"),
                                            _vm._v(" Do you wish to proceed? "),
                                            _c(
                                              "v-row",
                                              {
                                                staticClass:
                                                  "d-flex flex-row align-center justify-space-around",
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "1" } },
                                                  [
                                                    _c("v-checkbox", {
                                                      model: {
                                                        value: _vm.dontShow,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.dontShow = $$v
                                                        },
                                                        expression: "dontShow",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c("v-col", [
                                                  _c("span", [
                                                    _vm._v(
                                                      "Don't ask again in this session"
                                                    ),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "default",
                                        fn: function ({ showConfirm }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  fab: "",
                                                  color: "primary",
                                                  small: "",
                                                },
                                                on: { click: showConfirm },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-arrow-down-bold"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2096664023
                                  ),
                                })
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      "must-sort": "",
                      dense: _vm.dense,
                      "sort-by": "start_time",
                      "sort-desc": true,
                      headers: _vm.filteredHeaders,
                      items: _vm.list,
                      "hide-default-footer": "",
                      options: _vm.options,
                      "item-class": _vm.itemClass,
                      "server-items-length": _vm.options.itemsPerPage,
                      loading: _vm.loading,
                      "footer-props": _vm.footerProps,
                    },
                    on: {
                      "update:options": function ($event) {
                        _vm.options = $event
                      },
                      "click:row": _vm.handleClick,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "top",
                          fn: function ({ options, updateOptions }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-space-between align-center",
                                },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "search",
                                      staticClass: "col-3 pa-0 ml-6",
                                      model: {
                                        value: _vm.valid,
                                        callback: function ($$v) {
                                          _vm.valid = $$v
                                        },
                                        expression: "valid",
                                      },
                                    },
                                    [
                                      _vm._usrFlagsSome({
                                        key: `session.detail`,
                                        val: _vm.permissions.READ,
                                      })
                                        ? _c("v-text-field", {
                                            staticClass: "mt-4",
                                            attrs: {
                                              outlined: "",
                                              dense: "",
                                              label: "Search",
                                              "error-messages": _vm.searchErrs,
                                              dark: _vm.$vuetify.theme.dark,
                                              light: !_vm.$vuetify.theme.dark,
                                              rules: _vm.searchRules,
                                            },
                                            on: {
                                              blur: () => {
                                                _vm.valid = true
                                                ;(_vm.searchRules = []),
                                                  (_vm.searchErrs = [])
                                              },
                                              input: () => {
                                                _vm.valid = true
                                                ;(_vm.searchRules = []),
                                                  (_vm.searchErrs = [])
                                              },
                                              keydown: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                $event.preventDefault()
                                                return ((e) =>
                                                  _vm.onSubmit(
                                                    _vm.searchKey,
                                                    _vm.loadSessionSearch,
                                                    _vm.sessionSearch,
                                                    _vm.handleClick,
                                                    e
                                                  )).apply(null, arguments)
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: `prepend-inner`,
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            color:
                                                              "primary darken-2",
                                                            bottom: "",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                  attrs,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "div",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {},
                                                                          "div",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            staticClass:
                                                                              "mr-1",
                                                                            attrs:
                                                                              {
                                                                                size: "large",
                                                                                color: `primary ${
                                                                                  _vm
                                                                                    .$vuetify
                                                                                    .theme
                                                                                    .dark
                                                                                    ? ""
                                                                                    : "darken-2"
                                                                                }`,
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-help-circle "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              " Search by: "
                                                            ),
                                                            _c("br"),
                                                            _vm._v(
                                                              " InternalID, ExternalID, MongoID, Token or ExternalTicketID "
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: _vm.searchKey,
                                              callback: function ($$v) {
                                                _vm.searchKey = $$v
                                              },
                                              expression: "searchKey",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c("InfinitePagination", {
                                    attrs: {
                                      footer: _vm.footerProps,
                                      dense: _vm.dense,
                                      options: options,
                                      isFirstPage: _vm.isFirst,
                                      isLastPage: _vm.isLast,
                                    },
                                    on: {
                                      "update:dense": function ($event) {
                                        _vm.dense = $event
                                      },
                                      "update:options": updateOptions,
                                      pagination: _vm.mxPaginationHandler,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "foot",
                          fn: function ({ options, updateOptions }) {
                            return [
                              _c("tfoot", [
                                _c("tr", [
                                  _c("td", { attrs: { colspan: "100%" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex justify-end" },
                                      [
                                        _c("InfinitePagination", {
                                          attrs: {
                                            footer: _vm.footerProps,
                                            dense: _vm.dense,
                                            options: options,
                                            isFirstPage: _vm.isFirst,
                                            isLastPage: _vm.isLast,
                                          },
                                          on: {
                                            "update:dense": function ($event) {
                                              _vm.dense = $event
                                            },
                                            "update:options": updateOptions,
                                            pagination: _vm.mxPaginationHandler,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          },
                        },
                        {
                          key: `item.session_mongo_id`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex flex-row align-center" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "elevation-0 transparent",
                                      attrs: {
                                        icon: "",
                                        small: "",
                                        color: "kajot-text",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.copySession(
                                            item.session_mongo_id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("mdi-content-copy"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        "open-delay": 250,
                                        disabled: !_vm.shouldShortenString(
                                          item.session_mongo_id
                                        ),
                                        top: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "span",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("ellipsis")(
                                                            item.session_mongo_id
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(item.session_mongo_id)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.external`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex flex-row align-center" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "elevation-0 transparent",
                                      attrs: {
                                        icon: "",
                                        small: "",
                                        color: "kajot-text",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.copySession(item.external)
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("mdi-content-copy"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        "open-delay": 250,
                                        disabled: !_vm.shouldShortenString(
                                          item.external
                                        ),
                                        top: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "span",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("ellipsis")(
                                                            item.external
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(item.external)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.external_ticket`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    "open-delay": 250,
                                    disabled: !_vm.shouldShortenString(
                                      item.external_ticket
                                    ),
                                    top: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "span",
                                              _vm._g(
                                                _vm._b(
                                                  {},
                                                  "span",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("ellipsis")(
                                                        item.external_ticket
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(item.external_ticket)),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.player_mongo_id`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex flex-row align-center" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "elevation-0 transparent",
                                      attrs: {
                                        icon: "",
                                        small: "",
                                        color: "kajot-text",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.copySession(
                                            item.player_mongo_id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("mdi-content-copy"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        "open-delay": 250,
                                        disabled: !_vm.shouldShortenString(
                                          item.player_mongo_id
                                        ),
                                        top: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "span",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("ellipsis")(
                                                            item.player_mongo_id
                                                          )
                                                        ) +
                                                        _vm._s(
                                                          item.player
                                                            ? ` / ${item.player}`
                                                            : ``
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.player_mongo_id) +
                                            _vm._s(
                                              item.player
                                                ? ` / ${item.player}`
                                                : ``
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.start_time`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("Date")(item.start_time)) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.end_time`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("Date")(item.end_time)) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.sum_bet`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("Number")(item.sum_bet, {
                                      currency: item.currency,
                                    })
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.sum_win`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("Number")(item.sum_win, {
                                      currency: item.currency,
                                    })
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.netto`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("Number")(item.netto, {
                                      currency: item.currency,
                                    })
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.finished`,
                          fn: function ({ item }) {
                            return [
                              item.end_time
                                ? _c(
                                    "v-icon",
                                    { attrs: { color: "primary lighten-1" } },
                                    [_vm._v(" mdi-check ")]
                                  )
                                : _c("v-icon", { attrs: { color: "error" } }, [
                                    _vm._v(" mdi-close "),
                                  ]),
                            ]
                          },
                        },
                        {
                          key: `item.actions`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    bottom: "",
                                    color: "primary darken-2",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      color: "primary",
                                                      size: "22",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.onRowClick(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" mdi-alpha-i-circle ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v("Detail")])]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              staticStyle: {
                position: "fixed",
                bottom: "20px",
                "text-align": "center",
              },
              attrs: {
                app: "",
                transition: "slide-y-reverse-transition",
                timeout: 1000,
                color: "menu_background",
                "content-class": "kajot-text--text",
              },
              scopedSlots: _vm._u([
                {
                  key: "action",
                  fn: function () {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { fab: "", text: "", "x-small": "" },
                          on: {
                            click: function ($event) {
                              _vm.copied = false
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "kajot-text", small: "" } },
                            [_vm._v(" mdi-close ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.copied,
                callback: function ($$v) {
                  _vm.copied = $$v
                },
                expression: "copied",
              },
            },
            [
              _c(
                "div",
                { staticClass: "align-center" },
                [
                  _c("v-icon", { attrs: { color: "info" } }, [
                    _vm._v("mdi-information"),
                  ]),
                  _vm._v(" Copied to clipboard. "),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "FilterSideBar",
        {
          attrs: {
            filterIsEmpty: _vm.filterIsEmpty,
            refreshButton: "",
            loading: _vm.loading,
            width: _vm.isMobile ? "" : "400px",
          },
          on: { clear: _vm.clearFilter, refresh: _vm.reloadSessions },
        },
        [
          _c("SessionListForm", {
            ref: "filter",
            attrs: { filterIsEmpty: _vm.filterIsActive, loading: _vm.loading },
            on: { filter: _vm.useFilter },
            model: {
              value: _vm.filter,
              callback: function ($$v) {
                _vm.filter = $$v
              },
              expression: "filter",
            },
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "my-2 fakin-dialog",
          attrs: { width: 1200, "max-width": 1200 },
          model: {
            value: _vm.sessionModal,
            callback: function ($$v) {
              _vm.sessionModal = $$v
            },
            expression: "sessionModal",
          },
        },
        [
          _c("SessionDetailModal", {
            key: _vm.selectedSession,
            attrs: { item: _vm.selectedSession },
            on: {
              close: function ($event) {
                _vm.dialog = false
              },
            },
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          on: {
            "click:outside": _vm.exportClose,
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.exportClose.apply(null, arguments)
            },
          },
          model: {
            value: _vm.exportDialog,
            callback: function ($$v) {
              _vm.exportDialog = $$v
            },
            expression: "exportDialog",
          },
        },
        [
          _c("ExportDialog", {
            ref: "exportDialog",
            attrs: {
              builder: "paginatedObject",
              delimiter: ";",
              indeterminate: "",
              data: _vm.exportData,
              filename: `sessions`,
            },
            on: { close: _vm.exportClose },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }