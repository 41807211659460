<template>
	<div
		style="user-select: none"
		class="d-flex align-center">
		<div
			v-if="!hideDense"
			class="d-flex align-center">
			<div class="text-caption">Dense:</div>
			<v-icon
				:size="iconSize"
				v-ripple="false"
				class="ml-4 mr-6"
				:color="dense ? 'primary' : 'white'"
				@click="switchDense">
				{{ dense ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline" }}
			</v-icon>
		</div>
		<div class="d-flex align-center mr-8 text-caption">
			<div class="mr-8">Rows per page:</div>
			<v-select
				dense
				@input="onItemcountChange"
				:items="footer['items-per-page-options']"
				:value="options.itemsPerPage"
				style="width: 50px; font-size: 13px"
				hide-details
				class="mt-0 pt-0"></v-select>
		</div>
		<v-btn
			@click="$emit('firstPage'), $emit('pagination', 'first')"
			class="mx-2"
			:disabled="disabled || isFirstPage"
			v-if="footer.showFirstLastPage"
			text
			small
			icon>
			<v-icon :size="iconSize">
				{{ firstIcon }}
			</v-icon>
		</v-btn>
		<v-btn
			@click="$emit('previousPage'), $emit('pagination', 'previous')"
			class="mx-2"
			:disabled="isFirstPage || disabled"
			text
			small
			icon>
			<v-icon :size="iconSize">
				{{ previousIcon }}
			</v-icon>
		</v-btn>
		<v-btn
			@click="$emit('nextPage'), $emit('pagination', 'next')"
			class="mx-2"
			:disabled="isLastPage || disabled"
			text
			small
			icon>
			<v-icon :size="iconSize">
				{{ nextIcon }}
			</v-icon>
		</v-btn>
		<v-btn
			@click="$emit('lastPage'), $emit('pagination', 'last')"
			class="mx-2"
			:disabled="disabled || isLastPage"
			v-if="footer.showFirstLastPage"
			text
			small
			icon>
			<v-icon :size="iconSize">
				{{ lastIcon }}
			</v-icon>
		</v-btn>
	</div>
</template>

<script>
export default {
	data() {
		return {};
	},
	mixins: [],
	props: {
		hideDense: {
			type: Boolean,
			default: false,
		},
		dense: {
			type: Boolean,
		},
		isFirstPage: {
			type: Boolean,
			default: false,
		},
		isLastPage: {
			type: Boolean,
			default: false,
		},
		firstIcon: {
			type: String,
			default: "mdi-chevron-double-left",
		},
		previousIcon: {
			type: String,
			default: "mdi-chevron-left",
		},
		nextIcon: {
			type: String,
			default: "mdi-chevron-right",
		},
		lastIcon: {
			type: String,
			default: "mdi-chevron-double-right",
		},
		footer: {
			type: Object,
			default: () => ({}),
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		options: {
			type: Object,
			required: true,
		},
		iconSize: {
			type: Number,
			default: 20,
		},
	},
	methods: {
		switchDense() {
			this.$emit("update:dense", !this.dense);
		},
		onItemcountChange(value) {
			this.updateOptions({ ...this.options, itemsPerPage: value });
		},
		updateOptions(newOptions) {
			this.$emit("update:options", newOptions);
		},
	},
};
</script>
