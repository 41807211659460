var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex align-center",
      staticStyle: { "user-select": "none" },
    },
    [
      !_vm.hideDense
        ? _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c("div", { staticClass: "text-caption" }, [_vm._v("Dense:")]),
              _c(
                "v-icon",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple",
                      value: false,
                      expression: "false",
                    },
                  ],
                  staticClass: "ml-4 mr-6",
                  attrs: {
                    size: _vm.iconSize,
                    color: _vm.dense ? "primary" : "white",
                  },
                  on: { click: _vm.switchDense },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.dense
                          ? "mdi-checkbox-marked"
                          : "mdi-checkbox-blank-outline"
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "d-flex align-center mr-8 text-caption" },
        [
          _c("div", { staticClass: "mr-8" }, [_vm._v("Rows per page:")]),
          _c("v-select", {
            staticClass: "mt-0 pt-0",
            staticStyle: { width: "50px", "font-size": "13px" },
            attrs: {
              dense: "",
              items: _vm.footer["items-per-page-options"],
              value: _vm.options.itemsPerPage,
              "hide-details": "",
            },
            on: { input: _vm.onItemcountChange },
          }),
        ],
        1
      ),
      _vm.footer.showFirstLastPage
        ? _c(
            "v-btn",
            {
              staticClass: "mx-2",
              attrs: {
                disabled: _vm.disabled || _vm.isFirstPage,
                text: "",
                small: "",
                icon: "",
              },
              on: {
                click: function ($event) {
                  _vm.$emit("firstPage"), _vm.$emit("pagination", "first")
                },
              },
            },
            [
              _c("v-icon", { attrs: { size: _vm.iconSize } }, [
                _vm._v(" " + _vm._s(_vm.firstIcon) + " "),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-btn",
        {
          staticClass: "mx-2",
          attrs: {
            disabled: _vm.isFirstPage || _vm.disabled,
            text: "",
            small: "",
            icon: "",
          },
          on: {
            click: function ($event) {
              _vm.$emit("previousPage"), _vm.$emit("pagination", "previous")
            },
          },
        },
        [
          _c("v-icon", { attrs: { size: _vm.iconSize } }, [
            _vm._v(" " + _vm._s(_vm.previousIcon) + " "),
          ]),
        ],
        1
      ),
      _c(
        "v-btn",
        {
          staticClass: "mx-2",
          attrs: {
            disabled: _vm.isLastPage || _vm.disabled,
            text: "",
            small: "",
            icon: "",
          },
          on: {
            click: function ($event) {
              _vm.$emit("nextPage"), _vm.$emit("pagination", "next")
            },
          },
        },
        [
          _c("v-icon", { attrs: { size: _vm.iconSize } }, [
            _vm._v(" " + _vm._s(_vm.nextIcon) + " "),
          ]),
        ],
        1
      ),
      _vm.footer.showFirstLastPage
        ? _c(
            "v-btn",
            {
              staticClass: "mx-2",
              attrs: {
                disabled: _vm.disabled || _vm.isLastPage,
                text: "",
                small: "",
                icon: "",
              },
              on: {
                click: function ($event) {
                  _vm.$emit("lastPage"), _vm.$emit("pagination", "last")
                },
              },
            },
            [
              _c("v-icon", { attrs: { size: _vm.iconSize } }, [
                _vm._v(" " + _vm._s(_vm.lastIcon) + " "),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }